import axios from 'axios'
export default {
  namespaced: true,

  state: {

  },

  getters:{

  },

  mutations: {

  },
  actions: {
    async getContent({commit},payload){
        try {
            return await axios({
                method: 'get',
                url: payload.url,
                data: payload.data
            });
        } catch (e) {
            return e.response
        }
    },
    async submitContactUsForm({commit},payload){
        return axios({
            url: payload.url,
            method: 'POST',
            data: payload
        }).then((response) => {
            console.log('submit',response);
            if(response.status == 200 ){
                return {status:true}
            }return {status:false}
        }) .catch(error => {
            return {status:false,error:error}
        });
    },
  },
}