<template>
  <footer id="bottom" class="footer-wrap position-sticky player-ready" :style="(showPlayer) ? 'visibility: visible' : 'visibility: hidden; position: absolute !important;'">
    <div class="container-fluid">
      <div class="stickyfooter">
        <div class="vbry-player">
          <div class="d-flex justify-content-between">
            <div class="vbry-audio d-flex my-auto flex-grow-1">
              <div class="vbry-controls d-flex my-auto">
                <div class="my-auto">
                  <a href="#" :class="`prev mx-1 mx-md-2 ${this.songs[this.index - 1] ? '' : 'disabled'}`" @click.prevent="previous">
                    <ion-icon name="play-skip-back-sharp"></ion-icon>
                    <span class="sr-only">Back</span>
                  </a>
                </div>
                <div class="my-auto">
                  <a href="#" :class="`pause-play mx-lg-2 ${isPlaying ? 'playing' : ''}`" id="mainPLayer" @click.prevent="playSong">
                    <div class="pie-timer">
                      <div class="timer first-half">
                        <div class="left-mask"></div>
                        <div class="right-half"></div>
                        <div class="left-half spinner"></div>
                      </div>
                      <div class="disk"></div>
                    </div>
                    <ion-icon name="play-sharp"></ion-icon>
                    <ion-icon name="pause-sharp"></ion-icon>
                    <span class="sr-only">Play/Pause</span>
                  </a>
                </div>
                <div class="my-auto">
                  <a href="#" :class="`next mx-1 mx-md-2 ${this.songs[this.index + 1] ? '' : 'disabled'}`" @click.prevent="next">
                    <ion-icon name="play-skip-forward-sharp"></ion-icon>
                    <span class="sr-only">forward</span>
                  </a>
                </div>
              </div>
              <div class="audio-meta my-auto mx-sm-3 mx-1">
                <span class="title">{{ song ? song.title : '' }}</span>
                <span class="sub">{{ song && song.mood ? getMood(song.mood) : '' }}</span>
              </div>
              <div class="track-preview my-auto mx-3 d-flex flex-grow-1">
                <div class="mx-auto">
                  <a id="closePreview" class="py-2 mb-4" @click="closeFullscreenPlayer">
                    <span class="sr-only">Close</span>
                    <chevron-down-icon size="1.5x" class="custom-class"></chevron-down-icon>
                  </a>
                </div>
                <div :class="['vinyl-grp-wrap', 'playing', 'mx-auto', 'w-100', { 'animate-paused': !isPlaying }]" id="vinyl_record_mobile">
                  <div class="vinyl-grp">
                    <div class="vinyl-img">
                      <img src="/images/vinyl-disk.png" width="320" height="320" alt="album cover" loading="lazy" draggable="false">
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-grow-1 waveform-wrap">
                  <div class="vbry-playback flex-grow-1">
                    <div class="d-flex">
                      <div class="timestamp my-auto px-2">
                        <span id="seekTime"></span>
                      </div>
                      <div class="vbry-wave flex-grow-1 px-2 text-center my-lg-auto my-2">
                        <div id="minimap"></div>
                      </div>
                      <div class="length my-auto px-2">
                        <span id="endTime">0:00</span>
                      </div>
                    </div>
                  </div>
                  <div class="vbry-controls d-flex my-2 mx-auto justify-content-center">
                    <div class="my-auto">
                      <a href="#" :class="`prev mx-1 mx-md-2 ${this.songs[this.index - 1] ? '' : 'disabled'}`" @click.prevent="previous">
                        <ion-icon name="play-skip-back-sharp"></ion-icon>
                        <span class="sr-only">Back</span>
                      </a>
                    </div>
                    <div class="my-auto">
                      <a href="#" :class="`pause-play mx-lg-2 ${isPlaying ? 'playing' : ''}`" id="mainPLayer" @click.prevent="playSong">
                        <div class="pie-timer">
                          <div class="timer first-half">
                            <div class="left-mask"></div>
                            <div class="right-half"></div>
                            <div class="left-half spinner"></div>
                          </div>
                          <div class="disk"></div>
                        </div>
                        <ion-icon name="play-sharp"></ion-icon>
                        <ion-icon name="pause-sharp"></ion-icon>
                        <span class="sr-only">Play/Pause</span>
                      </a>
                    </div>
                    <div class="my-auto">
                      <a href="#" :class="`next mx-1 mx-md-2 ${this.songs[this.index + 1] ? '' : 'disabled'}`" @click.prevent="next">
                        <ion-icon name="play-skip-forward-sharp"></ion-icon>
                        <span class="sr-only">forward</span>
                      </a>
                    </div>
                  </div>
                  <div class="audio-meta py-2 mx-auto text-center">
                    <span class="title">{{ song ? song.title : '' }}</span>
                    <span class="sub">{{ song && song.mood ? getMood(song.mood) : '' }}</span>
                  </div>
                  <div class="vrby-vol my-auto d-flex px-3 py-2">
                    <div class="vrby-mute my-auto mx-1 mx-lg-2">
                      <a href="#" @click.prevent="mute" :class="`volume ${getVolumeLevel()}`" id="volumeCtr">
                        <volume-icon size="1.2x" class="custom-class"></volume-icon>
                        <volume-1-icon size="1.2x" class="custom-class"></volume-1-icon>
                        <volume-2-icon size="1.2x" class="custom-class"></volume-2-icon>
                        <volume-x-icon size="1.2x" class="custom-class"></volume-x-icon>
                        <span class="sr-only">Mute</span>
                      </a>
                    </div>
                    <div class="volslider my-auto mx-1 mx-lg-2 px-4 px-lg-3">
                      <input id="volume" class="slider" type="range" min="0" max="1" :value="currentVolume" step="0.1">
                    </div>
                  </div>
                </div>
                <div>
                  <a v-if="song && ((song.shortTrack != '' && song.shortTrack != null) || song.isSoundEffects)" @click="downloadSong" class="download-mobile btn-yellow my-2">
                    <span>Download</span>
                    <download-icon size="1x" class="custom-class"></download-icon>
                  </a>
                </div>
              </div>
            </div>
            <div class="dl my-auto d-none d-lg-flex mx-1 mx-md-2">
              <a v-if="song && ((song.shortTrack != '' && song.shortTrack != null) || song.isSoundEffects)" @click.prevent="downloadSong" class="dl-link">
                <download-icon size=".6x" class="custom-class"></download-icon>
                <span class="sr-only">Download</span>
              </a>
            </div>
            <div class="cta my-auto d-flex d-lg-none mx-1">
              <a class="mobile-more" id="mobilepreview" @click="showFullscreenPlayer">
                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ChevronDownIcon, DownloadIcon, VolumeIcon, Volume1Icon, Volume2Icon, VolumeXIcon, MoreVerticalIcon } from 'vue-feather-icons';
import { mapGetters } from "vuex";
import WaveSurfer from "wavesurfer.js";
import constant from "../../constants/constant";

export default {
  name: 'StickyPlayer',
  props: ['showPlayer'],
  components: {
    ChevronDownIcon,
    DownloadIcon,
    VolumeIcon,
    Volume1Icon,
    Volume2Icon,
    VolumeXIcon,
    MoreVerticalIcon
  },
  data() {
    return {
      previousVolume: 1,
      MAIN_URL: '',
      wavesurfer: null
    };
  },
  mounted() {
    console.log('mounted sticky player');
    this.MAIN_URL = constant.MAIN_URL;
  },
  created() {
    console.log('created');
    if (this.song && this.song.wavesurfer) {
      this.song.wavesurfer.stop();
      this.song.wavesurfer.destroyAllPlugins();
    }
  },
  watch: {
    song: {
      handler(newSong) {
        console.log('song watch', newSong);
        if (newSong) {
          if (newSong.previewTrack) {
            this.initWaveSurfer(newSong.previewTrack);
          } else {
            this.initWaveSurfer(newSong.shortTrack);
          }
        }
      },
      immediate: true
    },
    seekTo: {
      handler(seekTo) {
        // console.log('seekTo watch', seekTo);
        if (seekTo) {
          this.adjustTime(seekTo);
        }
      },
      immediate: true
    }
  },
  methods: {
    initWaveSurfer(audioUrl) {
      // alert('initWaveSurfer', audioUrl);
      if (this.wavesurfer) {
        this.wavesurfer.destroy();
      }

      this.wavesurfer = WaveSurfer.create({
        container: '#minimap',
        waveColor: "#ebebeb",
        width: '100%',
        progressColor: "#ffd300",
        cursorColor: "#fff0",
        height: 50,
        pixelRatio: 1,
        responsive: true,
        backend: 'MediaElement',
        normalize: true
      });
      this.$store.commit("player/SET_WAVESURFER", this.wavesurfer);

      const track = this.song.isSoundEffects ? this.song.soundEffectsTrack : this.song.previewTrack || this.song.shortTrack;
      const peaks = this.song.isSoundEffects ? this.song.soundEffectsPeaks : this.song.peaksPreview || this.song.peaks;

      if (peaks) {
        this.wavesurfer.load(this.MAIN_URL + '/tracks/' + track, JSON.parse(peaks));
      } else {
        this.wavesurfer.load(this.MAIN_URL + '/tracks/' + track);
      }

      this.wavesurfer.on('ready', () => {
        if (this.isPlaying) {
          this.wavesurfer.play();
          this.wavesurfer.setVolume(this.currentVolume);
          // this.wavesurfer.setVolume(0);
          this.interaction();

          document.querySelector('#volume').value = this.wavesurfer.getVolume();
          var volumeInput = document.querySelector('#volume');
          var onChangeVolume = (e) => {
            this.wavesurfer.setVolume(e.target.value);
            this.$store.commit("player/SET_VOLUME", this.wavesurfer.getVolume());
          };
          volumeInput.addEventListener('input', onChangeVolume);
          volumeInput.addEventListener('change', onChangeVolume);

        }
      });
      this.moveTime(this.wavesurfer);

      this.wavesurfer.on('finish', () => {
        this.wavesurfer.stop();
        this.next();
      });
    },
    interaction() {
      console.log('interaction');
      this.wavesurfer.on('interaction', () => {
        if (this.wavesurfer && this.wavesurfer.isPlaying()) {
          let totalTime = this.wavesurfer.getDuration();
          let currentTime = this.wavesurfer.getCurrentTime();
          let seek_to = currentTime / totalTime;
          this.$store.commit("player/SET_SEEKTO", seek_to);
        }
      });
    },
    playSong() {
      console.log('playSong', this.song);
      // this.wavesurfer.playPause();
      if(!this.currentWavesurfer){
        return;
      }

      this.currentWavesurfer.playPause();
      if (this.currentWavesurfer.isPlaying()) {
        this.$store.commit("player/SET_IS_PLAYING", true);
        // this.moveTime(this.currentWavesurfer);
        // document.getElementById('vinyl_record').classList.remove("animate-paused");
      } else {
        this.$store.commit("player/SET_IS_PLAYING", false);
        // document.getElementById('vinyl_record').classList.add("animate-paused");
      }
    },
    timeStringToSeconds(timeString) {
      console.log('timeStringToSeconds', timeString);
      const [minutes, seconds] = timeString.split(':').map(Number);
      return minutes * 60 + seconds;
    },
    divideTimes(time1, time2) {
      console.log('divideTimes', time1, time2);
      const time1InSeconds = this.timeStringToSeconds(time1);
      const time2InSeconds = this.timeStringToSeconds(time2);

      if (time2InSeconds === 0) {
        throw new Error('Division by zero');
      }

      return time1InSeconds / time2InSeconds;
    },
    adjustTime(seekTo) {
      console.log('adjustTime', seekTo);
      this.currentWavesurfer.seekTo(seekTo);
    },
    timeFormat(flt) {
      // console.log('timeFormat', flt);
      var min = parseInt(flt / 60),
          sec = parseInt(flt % 60) < 10 ? "0" + parseInt(flt % 60) : parseInt(flt % 60).toString();
      return min + ":" + sec;
    },
    next() {
      console.log('next', this.songs[this.index]);
      if (this.songs[this.index]) {
        var currentElement = document.getElementById(this.songs[this.index].id);
        if(currentElement){
          currentElement.click();
        }
      }

      if (this.songs[this.index + 1]) {
        var nextElement = document.getElementById(this.songs[this.index + 1].id);
        if (nextElement) {
          nextElement.click();
          console.log('next+1', this.songs[this.index + 1].title);
        }
      }
    },
    previous() {
      console.log('previous', this.songs[this.index]);
      if (this.songs[this.index]) {
        var currentElement = document.getElementById(this.songs[this.index].id);
        if(currentElement){
          currentElement.click();
        }
      }

      if (this.songs[this.index - 1]) {
        var nextElement = document.getElementById(this.songs[this.index - 1].id);
        if (nextElement) {
          nextElement.click();
          console.log('next-1', this.songs[this.index + 1].title);
        }
      }
    },
    getMood(mood) {
      console.log('getMood', mood);
      let songMood = "";
      if (mood && mood.length > 0) {
        let moods = [];
        mood.map(m => {
          moods.push(m.categoryName);
        });
        songMood = moods.join(", ");
      }
      return songMood;
    },
    mute() {
      console.log('mute');
      if (this.currentWavesurfer) {
        if (this.currentWavesurfer.getVolume() > 0) {
          this.previousVolume = this.currentWavesurfer.getVolume();
          this.currentWavesurfer.setVolume(0);
          this.$store.commit("player/SET_VOLUME", 0);
        } else {
          this.currentWavesurfer.setVolume(this.previousVolume);
          this.$store.commit("player/SET_VOLUME", this.previousVolume);
        }

        document.querySelector('#volume').value = this.currentWavesurfer.getVolume();
        var volumeInput = document.querySelector('#volume');
        var onChangeVolume = (e) => {
          this.currentWavesurfer.setVolume(e.target.value);
          this.$store.commit("player/SET_VOLUME", this.currentWavesurfer.getVolume());
        };
        volumeInput.addEventListener('input', onChangeVolume);
        volumeInput.addEventListener('change', onChangeVolume);
      }
    },
    showFullscreenPlayer() {
      console.log('showFullscreenPlayer');
      document.body.classList.toggle("prevent-scroll");
      document.querySelector(".track-preview").classList.toggle("active");
      document.querySelector(".footer-wrap").classList.toggle("showing-preview");
    },
    closeFullscreenPlayer() {
      console.log('closeFullscreenPlayer');
      document.querySelector(".track-preview").classList.add("closing");
      setTimeout(() => {
        document.body.classList.remove("prevent-scroll");
        document.querySelector(".footer-wrap").classList.remove("showing-preview");
        document.querySelector(".track-preview").classList.remove("active");
        document.querySelector(".track-preview").classList.remove("closing");
      }, 400);
    },
    getVolumeLevel() {
      console.log('getVolumeLevel');
      let level = "off";
      if (this.currentVolume > 0.5) {
        level = "high";
      } else if (this.currentVolume > 0 && this.currentVolume <= 0.5) {
        level = "low";
      } else if (this.currentVolume == 0) {
        level = "low mute";
      }
      return level;
    },
    downloadSong() {
      console.log('xxx downloadSong' + JSON.stringify(this.song));
      this.closeFullscreenPlayer();
      if (this.song) {
        if (this.song.wavesurfer && this.song.wavesurfer.isPlaying()) {
          this.song.wavesurfer.pause();
        }
        // this.$router.push({ path: "/download/" + this.song.slug });
        window.location.href = "/download/" + this.song.slug;
      }
    },
    moveTime(wavesurfer) {
      console.log('moveTime', wavesurfer);
      let mainPlayer = document.getElementById("mainPLayer");
      wavesurfer.on('audioprocess', () => {
        // console.log('audioprocess')
        if (wavesurfer && wavesurfer.isPlaying()) {
          let totalTime = wavesurfer.getDuration();
          let currentTime = wavesurfer.getCurrentTime();
          let remainingTime = totalTime - currentTime;
          let spinnerAngle = (currentTime / totalTime) * 360;

          mainPlayer.querySelector(".spinner").style.transform = "rotate(" + spinnerAngle + "deg)";
          if (spinnerAngle > 180) {
            mainPlayer.querySelector(".timer").classList.remove("first-half");
          } else {
            mainPlayer.querySelector(".timer").classList.add("first-half");
          }

          document.getElementById('seekTime').textContent = this.timeFormat(currentTime);
          document.getElementById('endTime').textContent = this.timeFormat(remainingTime);
        }
      });

      wavesurfer.on('interaction', () => {
        console.log('interaction', wavesurfer);
        if (wavesurfer && wavesurfer.isPlaying()) {
          let totalTime = wavesurfer.getDuration();
          let currentTime = wavesurfer.getCurrentTime();
          let seek_to = currentTime / totalTime;
          this.$store.commit("player/SET_SEEKTO", seek_to);
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      song: 'player/getSelectedSong',
      songs: 'player/getSongs',
      currentVolume: 'player/getVolume',
      isPlaying: 'player/getIsPlaying',
      currentWavesurfer: 'player/getCurrentWaveSurfer',
      loadedSongs: 'player/getLoadedSongs',
      seekTo: 'player/getSeekTo',
      index: 'player/getCurrentIndex'
    })
  }
};
</script>
