import Vue from 'vue'
import Vuex from 'vuex'
import player from './player'
import download from './download'
import content from './content'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    player,
    download,
    content
  }
})
