<template>
  <div id="app">
    <!--main content-->
<!--    {{showPlayer ? 't' : 'f'}}-->
<!--    {{isPlaying ? 't' : 'f'}}-->
    <HomeBanner v-if="isHome" />
    <Header />
    <router-view />
    <StickyPlayer :showPlayer="showPlayer || isPlaying" v-if="isHome"/>
    <!--end main content-->
  </div>
</template>

<script>
import HomeBanner from "./components/partials/HomeBanner.vue";
import Header from "./components/partials/Header.vue";

//import StickyPlayer from "./components/partials/StickyPlayer.vue";
import StickyPlayer from "./components/partials/StickyPlayer";
import {mapGetters} from "vuex";

export default {
  name: "App",
  components: {
    HomeBanner,
    Header,
    StickyPlayer,
  },
  computed: {
    isHome() {
      return this.$route.name === 'Home' || this.$route.name === 'HomeGenre'
          || this.$route.name === 'HomeMood' || this.$route.name === 'HomeSearch'
          || this.$route.name === 'HomeMusic' || this.$route.name == 'HomeSoundEffects'
    },
    ...mapGetters({
      showPlayer : 'player/getShowStickyPlayer',
      loading : 'player/getIsLoading',
      isPlaying: 'player/getIsPlaying',
    }),
  }
};
</script>
