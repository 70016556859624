<template>
    <section id="main" class="main-viewport">
		<div class="subpage-banner py-4 img-only" style="background-image:url('images/banners/subpage-banner.jpg');">
			&nbsp;
		</div>
		<div class="subpage-content content-wrap mh-auto">
			<!-- Content start -->
			<div class="container py-4 text-container">
				<div class="row no-gutters">
					<div class="col-12 col-lg-10 offset-lg-1 pt-3">
						<p v-html="content"></p>
					</div>
				</div>
			</div>
			<!-- Content end -->
		</div>
        <SubFooter />
	</section>
</template>
<script>
import SubFooter from "../partials/SubFooter.vue";
import {mapActions} from "vuex";
import constant from "../../constants/constant";

export default {
    components: {
        SubFooter,
    },
  data(){
    return {
      MAIN_URL:'',
      content:''
    }
  },
  async created() {
    this.main_url = constant.MAIN_URL;
    await this.getData();
  },
  methods:{
    ...mapActions({
      getContent:'content/getContent',
    }),
    async getData(){
      let payload = {
        url:this.main_url + '/api/content/faq',
      };
      const res = await this.getContent(payload);
      if(res.status == 200){
          this.content = res.data.content;
      }
    }
  }
}
</script>
