<template>
    <ContactForm />
</template>
<script>
import ContactForm from "../partials/ContactForm.vue";

export default {
    components: {
        ContactForm
    }
}
</script>
