<template>
	<section id="main" class="main-viewport">
		<div class="subpage-banner py-4 img-only" style="background-image:url('images/banners/subpage-banner.jpg');">
			&nbsp;
		</div>
		<div class="subpage-content content-wrap mh-auto">
			<!-- Content start -->
			<div id="contact-us" class="container py-4 text-container">
				<div class="row no-gutters">
					<div class="col-12 pt-3">
						<div class="row">
							<div class="col-12 col-md-5">
                <p v-html="content"></p>
							</div>
							<div class="col-12 col-md-7">
								<div class="form-container">
									<form method="post" action="#">
										<h2 class="text-center">CONTACT US</h2>
										<div v-if="sent" class="validation-msg success">We have received your message and would like
                      to thank you for writing to us. We will reply by
                      email as soon as possible.</div>
										<div class="validation-msg error">{{errorMsg}}</div>
										<div class="row">
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<input type="text" required="required" name="fullname" v-model="data.fullname" :class="`${data.fullname != '' ? 'has-value' : (errorMsg != '' ? 'error' : '')}`"/>
													<label for="fullname" class="control-label">Full Name</label><i class="bar"></i>
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<input type="email" required="required" name="email" v-model="data.email" :class="`${data.email != '' ? 'has-value' : (errorMsg != '' ? 'error' : '')}`"/>
													<label for="email" class="control-label">Email Address</label><i class="bar"></i>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<input type="text" required="required" name="country" v-model="data.country" :class="`${data.country != '' ? 'has-value' : (errorMsg != '' ? 'error' : '')}`"/>
													<label for="country" class="control-label">Country</label><i class="bar"></i>
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<input type="text" required="required" name="phone" v-model="data.phone" :class="`${data.phone != '' ? 'has-value' : (errorMsg != '' ? 'error' : '')}`"/>
													<label for="phone" class="control-label">Phone Number</label><i class="bar"></i>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<input type="text" name="company" v-model="data.company" :class="`${data.company != '' ? 'has-value' : ''}`"/>
													<label for="company" class="control-label">Company (optional)</label><i class="bar"></i>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<input type="text" required="required" name="subject" v-model="data.subject" :class="`${data.subject != '' ? 'has-value' : (errorMsg != '' ? 'error' : '')}`"/>
													<label for="subject" class="control-label">Subject</label><i class="bar"></i>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<textarea required="required" name="message" v-model="data.message" :class="`${data.message != '' ? 'has-value' : (errorMsg != '' ? 'error' : '')}`"></textarea>
													<label for="message" class="control-label">Message</label><i class="bar"></i>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-lg-6">
												<em style="margin: 1.5rem 0px;display:block;">

                          <div class="col-12" style="display: flex;align-items: center;">
                            <div id="google_captcha" class="g-recaptcha" data-sitekey="6Le5fdMaAAAAALjmRvxwtXXQrfwjsY5hEaWzTgj3"></div>
                          </div>
                          <div v-if="this.errorMsgCaptcha" class="col-12 validation-msg error" style="display: flex;align-items: center;">
                          {{this.errorMsgCaptcha}}
                          </div>

                        </em>
											</div>
											<div class="col-12 col-lg-6">
												<div class="button-container mr-auto">
                          <a
                              v-scroll-to="'#main'"
                              href="#"
                              class="scroll"
                              style="visibility: hidden"
                          >
                            test
                          </a>
                          <img v-if="loading" src="/images/mini-loader.gif" style="max-width: 52px;">
													<button v-if="!loading" type="button" class="button" @click="submit">
                            <span>SUBMIT <ion-icon name="send-sharp"></ion-icon></span>
                          </button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Content end -->
		</div>
		<SubFooter />
	</section>
</template>

<script>
import SubFooter from "../partials/SubFooter.vue";
import constant from "../../constants/constant";
import {mapActions} from "vuex";

export default {
    components: {
        SubFooter,
    },
    data(){
      return {
        loading:false,
        MAIN_URL:'',
        content:'',
        data:{
          fullname:'',
          email:'',
          country:'',
          phone:'',
          company:'',
          subject:'',
          message:'',
          sitekey:'6Le5fdMaAAAAALjmRvxwtXXQrfwjsY5hEaWzTgj3',
        },
        errorMsg:'',
        errorMsgCaptcha:'',
        sent:false
      }
    },
    async created() {
      //this.reCaptcha();
      this.main_url = constant.MAIN_URL;
      await this.getData();
    },
    methods:{
      ...mapActions({
        getContent:'content/getContent',
        submitContactUsForm:'content/submitContactUsForm',

      }),
      async getData(){
        let payload = {
          url:this.main_url + '/api/content/contactus',
        };
        const res = await this.getContent(payload);
        if(res.status == 200){
          this.content = res.data.content;
        }
      },
      async submit(){
        this.sent = false;

        let captchaToken = window.grecaptcha.getResponse();
        let required = [];

        if(captchaToken.length != 0) {
          this.errorMsgCaptcha = '';
        }

        if(this.data.fullname.trim() == ''){
          required.push("Full Name");
        }

        if(this.data.email.trim() == ''){
          required.push("Email");
        }

        if(this.data.country.trim() == ''){
          required.push("Country");
        }

        if(this.data.phone.trim() == ''){
          required.push("Phone");
        }

        if(this.data.message.trim() == ''){
          required.push("Message");
        }

        if(this.data.subject.trim() == ''){
          required.push("Subject");
        }

        if(required.length > 0){
          //this.errorMsg = required.join(', ') + (required.length > 1 ? ' are required' : ' is required');
          this.errorMsg = "Oops! You missed a required field."
          let link = document.getElementsByClassName('scroll');
          link[0].click();
          return;
        }
        this.errorMsg = '';
        if(captchaToken.length == 0) {
          this.errorMsgCaptcha = "Please confirm that you are not a robot."
          document.getElementById("google_captcha").className = "g-recaptcha red-captcha";
          return;
        }else {
          document.getElementById("google_captcha").className = "g-recaptcha";
        }
        this.errorMsgCaptcha = '';

        if(required.length == 0){
          this.loading = true;
          this.errorMsg = '';
          let payload = {
            url:this.main_url + '/api/send_contact_us',
            data:this.data,
            captchaToken:captchaToken
          };
          let result = await this.submitContactUsForm(payload);
          if(result.status){
            this.resetData();
            this.loading = false;
            this.sent = true;
            var elmnt = document.getElementById("main");
          }else {
            this.loading = false;
          }
          elmnt.scrollIntoView();
        }
      },
      resetData(){
        this.data.fullname = '';
        this.data.email = '';
        this.data.country = '';
        this.data.phone = '';
        this.data.company = '';
        this.data.subject = '';
        this.data.message = '';
      }
      // reCaptcha(){
      //   let self = this;
      //   setTimeout(() => {
      //     window.grecaptcha.render(document.getElementById('google_captcha'), {
      //       'sitekey' : self.sitekey
      //     });
      //   }, 1000)
      // },
    }
}
</script>

<style scoped>
  .red-captcha {
    border: 1px solid #f50000;
  }
</style>
