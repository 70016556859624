import axios from 'axios'
export default {
  namespaced: true,

  state: {

  },

  getters:{

  },

  mutations: {

  },
  actions: {
    async downloadSong({commit},payload){
        return axios({
            url: payload.url,
            method: 'POST',
            data: payload
        }).then((response) => {
            if(response.status == 200 ){
                return {status:true}
            }return {status:false}
        }) .catch(error => {
            return {status:false,error:error}
        });
    },
    async confirmDownloadSong({commit},payload){
           return axios({
              url: payload.url,
              method: 'POST',
              data: payload
          }).then((response) => {
              console.log('confirm download',response);
              if(response.status == 200 ){
                  return {
                      status:response.data.status,
                      version:response.data.version,
                      music_id:response.data.music_id,
                  }
              }return {status:false}
          }) .catch(error => {
              return {status:false,error:error}
          });
    },
  },
}