<template>
  <!-- Homepage Banner -->
  <div :class="`container-fluid homepage-banner ${loading ? 'loading' : ''}`">
    <div class="loader">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
      <div class="bar5"></div>
      <div class="bar6"></div>
    </div>

    <div class="banner-wrap">
      <div class="banner-frame">
        <div class="row">
          <div class="col-12">
            <div class="logo-line">
              <a href="/" class="d-inline-block">
                <img
                  src="/images/Logo-white.png"
                  alt="Viberary"
                  draggable="false"
                  width="216"
                  height="56"
                />
              </a>
            </div>
            <div class="herobanner-wrap">
              <div
                class="hero-banner"
                id="particles-js"
                style="
                  background-image: url('/images/banners/hero-banner.png');
                "
              ></div>
              <div class="caption">
                <h2 class="title">
                  <span class="lg d-block">Get Unlimited Free</span>
                  Music For Your Videos
                </h2>
                <p>
                  Worry-Free. No Sign Up. Safe for YouTube 
                  <br class="d-block d-md-none" />monetization and commercial use.
                </p>
              </div>
              <a
                v-scroll-to="'#top'"
                href="#"
                class="scroll"
              >
                <ion-icon name="arrow-down-circle-outline"></ion-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Homepage Banner end -->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  created() {
    this.$loadScript("/js/particles.min.js")
      .then(() => {
        //particle js init - Hero Banner
        // *note - this plugin doesn't have a refresh callback, resizing window leads to inconsistensies
        // https://github.com/VincentGarreau/particles.js/#readme
        particlesJS("particles-js", {
          particles: {
            number: { value: 100, density: { enable: true, value_area: 800 } },
            color: { value: "#ffffff" },
            shape: {
              type: "circle",
              stroke: { width: 0, color: "#000000" },
              polygon: { nb_sides: 5 },
              image: { src: "img/github.svg", width: 100, height: 100 },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
            },
            size: {
              value: 2,
              random: true,
              anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { enable: true, mode: "grab" },
              onclick: { enable: true, mode: "push" },
              resize: true,
            },
            modes: {
              grab: { distance: 150, line_linked: { opacity: 1 } },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: { distance: 200, duration: 0.4 },
              push: { particles_nb: 4 },
              remove: { particles_nb: 2 },
            },
          },
          retina_detect: true,
        });
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
  computed : {
    ...mapGetters({
      loading : 'player/getIsLoading',
    }),
  }
};
</script>
