import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Home from './components/pages/Home.vue'
import Download from './components/pages/Download.vue'
import About from './components/pages/About.vue'
import LicenseTerms from './components/pages/LicenseTerms.vue'
import PrivacyPolicy from './components/pages/PrivacyPolicy.vue'
import TermsOfUse from './components/pages/TermsOfUse.vue'
import FAQ from './components/pages/FAQ.vue'
import ContactUs from './components/pages/ContactUs.vue'

// Test Pages
import Contact from './components/pages/Contact.vue'


const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home
    },
    {
        name: 'HomeMood',
        path: '/mood/:slug',
        component: Home,
        props: (route) => ({slug: route.params.slug,type:'mood'}),
    },
    {
        name: 'HomeGenre',
        path: '/genre/:slug',
        component: Home,
        props: (route) => ({slug: route.params.slug,type:'genre'}),
    },
    {
        name: 'HomeSearch',
        path: '/search/:slug',
        component: Home,
        props: (route) => ({slug: route.params.slug,type:'search'}),
    },
    {
        name: 'HomeMusic',
        path: '/homemusic',
        component: Home
    },
    {
        name: 'Download',
        path: '/download/:slug',
        component: Download
    },
    {
        name: 'DownloadSoundEffects',
        path: '/download/soundeffects/:slug',
        component: Download,
        props: (route) => ({downloadSoundEffect: true}),
    },
    {
        name: 'About',
        path: '/about',
        component: About
    },
    {
        name: 'LicenseTerms',
        path: '/license-terms',
        component: LicenseTerms
    },
    {
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        name: 'TermsOfUse',
        path: '/terms-of-use',
        component: TermsOfUse
    },
    {
        name: 'FAQ',
        path: '/faq',
        component: FAQ
    },
    {
        name: 'ContactUs',
        path: '/contact-us',
        component: ContactUs
    },,
    {
        name: 'Contact',
        path: '/contact',
        component: Contact
    },
    {
        name: 'HomeSoundEffects',
        path: '/soundeffects',
        component: Home,
        props: (route) => ({slug: route.params.slug}),
    },
]

export default new Router({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
      },
    routes: routes
})