import Vue from 'vue';
import App from './App.vue';
import router from './router';
import LoadScript from 'vue-plugin-load-script';
window.axios = require('axios');
var VueScrollTo = require('vue-scrollto');
import VueClipboard from 'vue-clipboard2';
import store from './store'

Vue.use(VueClipboard)
Vue.use(VueScrollTo)

//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.use(LoadScript);
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
