<template>
    <!-- Header -->
	<header id="top" class="header-wrap position-sticky">
		<div class="container-fluid">
			<div class="mainheader">
                <div class="row">
                    <div class="col-12 d-block text-center d-lg-none my-auto usp-line-wrap">
                        <span class="usp-line m-0">BEST SOUNDS IN LIFE ARE FREE</span>
                    </div>
                    <div class="col-12 col-lg-auto sbar-w my-auto">
                        <div id="mobile-close-button" class="filter-btn d-lg-none d-flex" @click="showMobileFilters" v-if="isHome">
                            <sliders-icon size="1.5x" class="custom-class m-auto"></sliders-icon>
                            <x-icon size="1.5x" class="custom-class m-auto"></x-icon>
                        </div>
                        <div class="vbry-logo">
                            <a href="/" class="d-inline-block">
<!--                              <router-link to="/home" class="d-inline-block">-->
                                <img src="/images/Logo.png" alt="Viberary" draggable="false" width="216" height="56">
<!--                              </router-link>-->
                            </a>
                        </div>
                        <div class="search-btn d-lg-none d-flex">
                            <search-icon size="1x" class="custom-class m-auto" @click="showSearchBar" v-if="!isSearchBar"></search-icon>
                            <arrow-right-icon size="1.5x" class="custom-class m-auto" @click="closeSearchBar" v-else></arrow-right-icon>
                        </div>
                    </div>
                    <div class="col-12 col-lg-auto flex-grow-1 top-pane">
                        <div class="row">
                            <div class="col-12 d-none d-lg-block order-1 order-lg-2 col-lg-3 my-auto usp-line-wrap">
                                <span class="usp-line m-0">BEST SOUNDS IN LIFE ARE FREE</span>
                            </div>
                            <div class="col-12 order-2 order-lg-1 col-lg-9 my-auto search-box-wrap">
                                <div class="search-box" :class="{ active: isSearchBar }">
                                    <div class="sfield">
                                        <input @keyup.enter="search" v-model="inputKeyWord" type="search" name="s" placeholder="Search Music">
                                        <button type="submit" @click.prevent="search">
                                            <search-icon size="1x" class="custom-class m-auto"></search-icon>
                                        </button>
                                        <button class="cancel d-inline-block d-lg-none" @click="closeSearchBar">
                                            <x-icon size="1x" class="custom-class m-auto"></x-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</header>
	<!-- Header end-->
</template>
<script>
import { SlidersIcon } from 'vue-feather-icons'
import { XIcon } from 'vue-feather-icons'
import { SearchIcon } from 'vue-feather-icons'
import { ArrowRightIcon } from 'vue-feather-icons'
import {mapActions, mapGetters} from "vuex";
import constant from "../../constants/constant";


export default {
  components: {
    SlidersIcon,
    XIcon,
    SearchIcon,
    ArrowRightIcon
  },
  data() {
      return {
          isSearchBar: false,
          inputKeyWord:''
      }
  },
  mounted() {
    this.MAIN_URL = constant.MAIN_URL;
  },
  methods: {
    ...mapActions({
      fetchSongs:'player/fetchSongs',
    }),
    showMobileFilters() {
        document.getElementById("top").querySelector(".filter-btn").classList.toggle("active");
        if(document.querySelector(".vbry-filters").classList.contains('active')){
            document.querySelector(".vbry-filters").classList.add("closing");
            setTimeout(function(){ //wait for closing animation 
                document.querySelector(".vbry-filters").classList.remove("closing");
                document.querySelector(".vbry-filters").classList.remove("active");
                document.body.classList.remove("prevent-scroll");
            }, 400);
        }else{
            document.querySelector(".vbry-filters").classList.add("active");
            document.body.classList.add("prevent-scroll");
        }
    },
    showSearchBar() {
        this.isSearchBar = true
    },
    closeSearchBar() {
        this.isSearchBar = false
    },
    async search(){
          if(this.inputKeyWord && this.inputKeyWord.trim() != ''){
            if(this.$route.name != 'Home' && this.$route.name != 'HomeSearch'){
              await this.$router.push({ path:'/search/'+this.inputKeyWord});
            }else{
              await this.getSongs(this.inputKeyWord,'search');
            }
          }
    },
    async getSongs(slug,type){
      this.$store.commit("player/SET_SONGS" , []);
      this.$store.commit("player/SET_LOADED_SONGS" , 0)
      if(this.selectedSong && this.selectedSong.wavesurfer){
        this.selectedSong.wavesurfer.stop();
        this.selectedSong.wavesurfer.destroyAllPlugins();
      }
      // document.getElementById('vinyl_record').classList.add("animate-paused");
      this.$store.commit("player/SET_IS_PLAYING" , false);
      this.$store.commit("player/SET_SHOW_STICKY_PLAYER" , false);
      this.$store.commit("player/SET_SELECTED_SONG" , null);
      let self = this;
      await this.fetchSongs({url:this.MAIN_URL + "/api/"+type+"/" +slug});
      await self.preparePlayers();
    },
    async preparePlayers(){
      let self = this;
      return new Promise((resolve) => {
        let newsongs = [];
        document.querySelectorAll(".song-row").forEach((song) => {
          //slug of song
          let song_slug = song['id'];
          let audio = song.querySelector(".audio");
          let src = audio["src"];
          let waveform = song.querySelector(".waveform");
          let wavesurfer = WaveSurfer.create({
            container: waveform,
            barWidth: 2,
            barHeight: 1.6,
            barGap: null,
            waveColor: "#ebebeb",
            progressColor: "#ffd300",
            cursorColor: "#fff0",
            height: 34,
            pixelRatio: 1,
            responsive: true,
            backend: 'MediaElement'
          });

          self.songs.map((song) => {
            if(song_slug == song.slug){
              if(song.peaks){
                wavesurfer.load(src,JSON.parse(song.peaks));
                // wavesurfer.backend.setPeaks(JSON.parse(song.peaks));
              }else{
                wavesurfer.load(src);
              }

              //for duration maybe optimize by saving it on create of music on admin
              wavesurfer.on('ready', function () {
                self.$store.commit("player/SET_LOADED_SONGS" , self.loadedSongs + 1)
                song.isReady = true;
                self.$store.commit("player/UPDATE_SONGS_TIME" , song);
              });
              song.wavesurfer =  wavesurfer;
              newsongs.push(song);
            }
          });
        });
        self.$store.commit("player/SET_SONGS" , newsongs);
        resolve(true);
      });
    },
  },
  computed: {
    isHome() {
      return this.$route.name === 'Home'
    },
    ...mapGetters({
      selectedSong : 'player/getSelectedSong',
      songs : 'player/getSongs',
      loadedSongs:'player/getLoadedSongs',
    }),
  }
}
</script>