<template>
    <div class="subpage-footer">
        <div class="container">
            <footer id="mainFooter">
                <div class="px-md-4 mx-md-4">
                    <div class="d-flex footer-menu justify-content-center mb-2 flex-wrap">
                        <a href="/" class="mx-2 mx-md-4 px-md-4 mb-2">Home</a>
                        <router-link to="/about" class="mx-2 mx-md-4 px-md-4 mb-2">About</router-link>
                        <a href="https://blog.viberary.com/" class="mx-2 mx-md-4 px-md-4 mb-2">Blogs</a>
                        <router-link to="/faq" class="mx-2 mx-md-4 px-md-4 mb-2">FAQ</router-link>
                        <a href="/contact-us" class="mx-2 mx-md-4 px-md-4 mb-2">Contact Us</a>
                    </div>
                    <div class="bottom-links d-md-flex my-4 justify-content-between flex-wrap text-center">
                        <div class="order-1 order-md-2 mb-4 mb-md-auto mt-md-auto px-1 pb-3 mx-auto">
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                <router-link to="/license-terms">License Terms</router-link>
                                </div>
                                <div class="col-auto">
                                <router-link to="/privacy-policy">Privacy Policy</router-link>
                                </div>
                                <div class="col-auto">
                                <router-link to="/terms-of-use">Terms of Use</router-link>
                                </div>
                            </div>
                        </div>
                        <p class="order-2 order-md-1 my-auto pb-3 px-1 mx-auto">Viberary.com All Rights Reserved. Copyright &copy; {{ currentYear }}</p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {currentYear: null}
  },
  created() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>